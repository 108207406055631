<template>
  <div>
    <h3>Your plants</h3>
    <ul>
      <li v-for="plant in plants" :key="plant.name">
        {{ plant.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Plants',
  setup() {
    const plants = [{name: "Monstera"}, {name: "Philodendron micans"}];

    return {
      plants,
    };
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
