<template>
  <a href="#">
    <img alt="Flora logo" src="./assets/logo.svg" style="height: 10vh" />
  </a>
  <h1>
    Welcome to Flora
  </h1>
  <router-view></router-view>
  <div>
    <a href="https://www.vecteezy.com/free-vector/leaves">
      Leaves Vectors by Vecteezy
    </a>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #305030;
  margin-top: 60px;
}
</style>
